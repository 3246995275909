<template>
  <div>
    <el-dialog
      title="Document Expiration & Reminders"
      :visible.sync="docSettingsVisible"
      top="25px"
      :before-close="close"
      class="outersize-confirm-type-three"
      width="100%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="settingsData" class="sidemenu">
        <el-row>
          <el-col :span="24">
            <div class="card-body">
              <div class="py-3 px-2">
                <h4 class="fs-6 text-dark fw-medium">Secure E-Sign</h4>
                <el-switch
                  v-model="settings.secure_e_sign.allow_document_e_sign"
                  :active-value="true"
                  :inactive-value="false"
                  active-text="Secure E-Sign"
                >
                </el-switch>
              </div>
              <div
                class="mb-1"
                v-if="settings.secure_e_sign.allow_document_e_sign"
              >
                <el-checkbox-group
                  v-model="settings.secure_e_sign.notify_through"
                >
                  <el-checkbox label="Send_Email">Send Email</el-checkbox>
                  <el-checkbox label="Send_Sms">Send Sms</el-checkbox>
                  <!-- <el-checkbox label="Send_Whatsapp">Send Whatsapp</el-checkbox>  -->
                </el-checkbox-group>
                <span
                  style="color: red"
                  ref="errorElement"
                  v-if="
                    sendError &&
                    settings.secure_e_sign.notify_through &&
                    settings.secure_e_sign.notify_through.length === 0
                  "
                >
                  Please Select Atleast One Option
                </span>
              </div>
            </div>
            <el-divider class="m-0"></el-divider>
            <div class="card-body">
              <div class="pb-3 px-2">
                <h4 class="fs-6 text-dark fw-medium">Update entity data</h4>
                <p>
                  If you used entity fields on document. You can update the
                  entity data by that document data.
                </p>
                <div class="d-flex w-100 align-items-center">
                  <el-radio-group v-model="settings.update_entity_data">
                    <el-radio :label="0"
                      >Update after document completion</el-radio
                    >
                    <el-radio :label="1"
                      >Update after user finishes the document</el-radio
                    >
                    <el-radio :label="2">Never update</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
            <el-divider class="m-0"></el-divider>
            <div class="card-body">
              <div class="pb-3 px-2">
                <h4 class="fs-6 text-dark fw-medium">
                  Document Generation Settings
                </h4>
                <div class="d-flex align-items-center">
                  <el-checkbox
                    v-model="
                      settings.document_generation_settings
                        .add_document_generated_id
                    "
                  >
                    Add eSigns generated id</el-checkbox
                  >
                  <el-checkbox
                    v-model="
                      settings.document_generation_settings.add_page_number
                    "
                  >
                    Add page numbers</el-checkbox
                  >
                </div>
              </div>
            </div>
            <el-divider class="m-0"></el-divider>
            <div class="card-body">
              <div class="pb-3 px-2">
                <h4 class="fs-6 text-dark fw-medium mt-1">
                  Expiration Settings
                </h4>
                <p>
                  Set the expiry date for your document validity so the system
                  does it for you automatically.
                </p>
                <div class="d-flex w-100 align-items-center">
                  <div style="max-width: 200px">
                    {{
                      getIsMobile
                        ? "Expire sent docs in"
                        : "Expire sent documents in"
                    }}
                  </div>
                  <div class="pl-2" style="max-width: 180px">
                    <el-input
                      placeholder="0"
                      min="1"
                      type="number"
                      @change="changeExpireDate"
                      v-model="
                        settings.expiration_settings.expire_documents_in_days
                      "
                      size="medium"
                    >
                      <template slot="append">
                        <i class="el-icon-date"></i> days</template
                      >
                    </el-input>
                  </div>
                </div>
                <div class="expiration-reminder-check mt-1">
                  <div class="d-flex align-items-center">
                    <div class="px-2 word-break-normal">Expire Date</div>
                    <el-date-picker
                      v-model="
                        settings.expiration_settings.document_expired_date
                      "
                      disabled
                    ></el-date-picker>
                  </div>
                </div>
                <div class="expiration-reminder-check mt-1">
                  <div class="d-flex align-items-center">
                    <el-checkbox
                      v-model="
                        settings.expiration_settings
                          .send_expiration_reminder_status
                      "
                    ></el-checkbox>
                    <div class="px-2 word-break-normal">
                      {{ getIsMobile ? "Remind in" : "Send First Reminder" }}
                    </div>
                    <el-input-number
                      v-model="
                        settings.expiration_settings.send_first_reminder_in_days
                      "
                      :disabled="
                        !settings.expiration_settings
                          .send_expiration_reminder_status
                      "
                      :min="1"
                      :max="
                        settings.expiration_settings.expire_documents_in_days
                      "
                    ></el-input-number>
                    <div class="pl-1">Before Expiration</div>
                  </div>
                </div>
                <div class="expiration-reminder-check mt-1">
                  <div class="d-flex align-items-center">
                    <el-checkbox
                      v-model="
                        settings.expiration_settings
                          .repeat_expiration_reminder_status
                      "
                    ></el-checkbox>
                    <div class="px-2 word-break-normal" style="overflow-wrap: ">
                      {{ getIsMobile ? "Repeat" : "Repeat Reminder" }}
                    </div>
                    <el-input-number
                      v-model="
                        settings.expiration_settings.repeat_expiration_reminder
                      "
                      :disabled="
                        !settings.expiration_settings
                          .repeat_expiration_reminder_status
                      "
                      :min="1"
                      :max="
                        settings.expiration_settings.expire_documents_in_days
                      "
                    ></el-input-number>
                    <div class="pl-1">in Days</div>
                  </div>
                </div>
              </div>
            </div>
            <el-divider class="m-0"></el-divider>
            <div class="card-body">
              <div class="py-3 px-2">
                <h4 class="fs-6 text-dark fw-medium">
                  Auto Reminders Settings
                </h4>
                <p>
                  Automatically send email reminders to signers regarding the
                  incompletion of document.
                </p>
                <div class="d-flex w-100 align-items-center mb-1">
                  <el-checkbox
                    v-model="
                      settings.auto_remainders_settings.first_reminder_status
                    "
                  ></el-checkbox>
                  <div class="px-2 word-break-normal" style="max-width: 200px">
                    {{ getIsMobile ? "Remind in" : "Send First Reminder in" }}
                  </div>
                  <div class="pl-2" style="max-width: 180px">
                    <el-input
                      placeholder="0"
                      type="number"
                      min="0"
                      :max="
                        settings.expiration_settings.expire_documents_in_days
                      "
                      :disabled="
                        !settings.auto_remainders_settings.first_reminder_status
                      "
                      v-model="
                        settings.auto_remainders_settings
                          .send_first_reminder_in_days
                      "
                      size="medium"
                    >
                      <template slot="append">
                        <i class="el-icon-date"></i> days</template
                      >
                    </el-input>
                  </div>
                </div>

                <div class="expiration-reminder-check">
                  <div class="d-flex align-items-center">
                    <el-checkbox
                      v-model="
                        settings.auto_remainders_settings.repeat_reminder_status
                      "
                    ></el-checkbox>
                    <div
                      class="px-2 word-break-normal"
                      style="width: 100px; padding-right: 0px !important"
                    >
                      {{ getIsMobile ? "Repeat" : "Repeat Reminder" }}
                    </div>
                    <el-input-number
                      v-model="
                        settings.auto_remainders_settings.repeat_reminder
                      "
                      :disabled="
                        !settings.auto_remainders_settings
                          .repeat_reminder_status
                      "
                      :min="1"
                      :max="
                        settings.expiration_settings.expire_documents_in_days
                      "
                    ></el-input-number>
                    <div class="pl-1">
                      {{
                        getIsMobile ? "Days" : "Until completed &amp; expired"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-divider class="m-0"></el-divider>
        <el-form-item class="mb-0 pt-2 px-2 text-right">
          <el-button @click="close">Cancel</el-button>
          <el-button
            style="background-color: #409eff; color: #ffffff"
            class="type-2"
            @click="updateDocSettingsData"
            >Save & Send
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
export default {
  name: "configureDocuments-ConfigureSettings",
  props: {
    docSettingsVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    settingsData: Object,
  },

  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("documents", ["getDocumentSettings"]),
    ...mapGetters("configurableDocuments", [
      "getConfigurableDocumentExpirationSettigns",
      "getConfigurableDocumentUpdateStatus",
    ]),
  },
  ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
  // ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
  data() {
    return {
      sendError: false,
      settings: {
        secure_e_sign: {
          allow_document_e_sign: false,
          notify_through: [],
        },
        auto_remainders_settings: {
          send_first_reminder_in_days: 0,
          repeat_reminder: 0,
          repeat_reminder_status: true,
          first_reminder_status: true,
        },
        update_entity_data: 0,
        document_generation_settings: {
          add_document_generated_id: true,
          add_page_number: true,
        },
        expiration_settings: {
          send_expiration_reminder_status: true,
          repeat_expiration_reminder_status: true,
          send_first_reminder_in_days: 0,
          is_default: true,
          repeat_expiration_reminder: 0,
          expire_documents_in_days: 0,
          document_expired_date: "",
        },
      },
      expirationRemainder: true,
      sendFirstRemainder: true,
      repeatRemainderCheck: true,
    };
  },
  async mounted() {
    await this.fetchConfigurableDocumentSettings({
      id: this.$route.params.id,
    });
    await this.setUserSettings();

    // if (this.getDocumentSettings) {
    //   this.changeExpireDate();
    //   let data = this.getDocumentSettings;

    //   this.settings.expiration_settings.send_expiration_reminder_status =
    //     data.data.expiration_settings.send_expiration_reminder_status;
    //   this.settings.expiration_settings.repeat_expiration_reminder_status =
    //     data.data.expiration_settings.repeat_expiration_reminder_status;
    //   this.settings.expiration_settings.expire_documents_in_days =
    //     data.data.expiration_settings.expire_documents_in_days;
    //   this.settings.expiration_settings.send_first_reminder_in_days =
    //     data.data.expiration_settings.send_first_reminder_in_days;
    //   this.settings.expiration_settings.repeat_expiration_reminder =
    //     data.data.expiration_settings.repeat_expiration_reminder;
    //   this.settings.auto_remainders_settings.repeat_reminder =
    //     data.data.auto_remainders_settings.repeat_reminder;
    //   this.settings.auto_remainders_settings.send_first_reminder_in_days =
    //     data.data.auto_remainders_settings.send_first_reminder_in_days;
    //   this.settings.auto_remainders_settings.repeat_reminder_status =
    //     data.data.auto_remainders_settings.repeat_reminder_status;
    //   this.settings.auto_remainders_settings.first_reminder_status =
    //     data.data.auto_remainders_settings.first_reminder_status;
    //   this.changeExpireDate();
    // } else {
    //   this.fetchUserSettings();
    //   this.changeExpireDate();
    // }
  },

  methods: {
    ...mapActions({
      fetchConfigurableDocumentSettings:
        "configurableDocuments/fetchConfigurableDocumentExpirationSettignsByID",
    }),
    scrollToError() {
      if (this.$refs.errorElement) {
        this.$refs.errorElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    close() {
      this.$emit("close");
    },
    async fetchUserSettings() {
      try {
        this.loading = true;
        await this.$store.dispatch("settings/fetchUserDocumentSettings");
        this.setUserSettings();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    setUserSettings() {
      let data = this.getConfigurableDocumentExpirationSettigns;

      this.settings.expiration_settings.send_expiration_reminder_status =
        data.expiration_settings.send_expiration_reminder_status;
      this.settings.expiration_settings.repeat_expiration_reminder_status =
        data.expiration_settings.repeat_expiration_reminder_status;
      this.settings.expiration_settings.expire_documents_in_days =
        data.expiration_settings.expire_documents_in_days;
      this.settings.expiration_settings.send_first_reminder_in_days =
        data.expiration_settings.send_first_reminder_in_days;
      this.settings.expiration_settings.repeat_expiration_reminder =
        data.expiration_settings.repeat_expiration_reminder;
      this.settings.auto_remainders_settings.repeat_reminder =
        data.auto_remainders_settings.repeat_reminder;
      this.settings.auto_remainders_settings.send_first_reminder_in_days =
        data.auto_remainders_settings.send_first_reminder_in_days;
      this.settings.auto_remainders_settings.repeat_reminder_status =
        data.auto_remainders_settings.repeat_reminder_status;
      this.settings.auto_remainders_settings.first_reminder_status =
        data.auto_remainders_settings.first_reminder_status;
      this.settings.secure_e_sign.allow_document_e_sign =
        data.secure_e_sign.allow_document_e_sign;
      this.settings.update_entity_data = data.update_entity_data;
      this.settings.document_generation_settings.add_document_generated_id =
        data.document_generation_settings.add_document_generated_id;
      this.settings.document_generation_settings.add_page_number =
        data.document_generation_settings.add_page_number;
      this.settings.secure_e_sign.notify_through =
        data.secure_e_sign.notify_through;
      this.changeExpireDate();
    },

    async updateDocSettingsData() {
      
      if (
        this.settings.secure_e_sign &&
        this.settings.secure_e_sign.allow_document_e_sign === true &&
        this.settings.secure_e_sign.notify_through.length < 1
      ) {
        this.sendError = true;
        this.$nextTick(() => {
          this.scrollToError();
        });
        console.log("calling this settings");
      } else {
        this.loading = true;
        let params = {
          id: this.$route.params.id,
          configurable_document_id: this.$route.params.id,
          document_expiration_settings: this.settings,
        };

        await this.$store.dispatch(
          "configurableDocuments/updateConfigurableDocument",
          params
        );
        if (this.getConfigurableDocumentUpdateStatus) {
          this.loading = false;
          this.$notify.success({
            title: "Success",
            message: "Settings updated successfully",
          });
          this.$emit("updateSettingsData");
        }
        // this.$emit("updateSettingsData");
      }
    },
    changeExpireDate() {
      this.settings.expiration_settings.document_expired_date =
        this.$moment().add(
          this.settings.expiration_settings.expire_documents_in_days,
          "days"
        );
    },
  },
  beforeDestroy() {
    this.$store.commit("documents/setDocumentSettings", null, { root: true });
    this.$store.commit(
      "configurableDocuments/setConfigurableDocumentExpirationSettigns",
      null,
      { root: true }
    );
    this.$store.commit(
      "configurableDocuments/setConfigurableDocumentUpdateStatus",
      null,
      {
        root: true,
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.sidemenu {
  height: 550px;
  overflow-y: scroll;
  overflow-x: scroll;

  margin-right: 5px;
}
.sidemenu::-webkit-scrollbar {
  width: 0.7em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.expiration-reminder-check {
  margin-bottom: 15px;
}
</style>
